import uniq from 'lodash/uniq';
import { Calculators, Filters, Providers, Enums, Selectors, Entities } from '@flightscope/baseball-stats';
// import { genericChartFilters } from '@flightscope/baseball-stats/src/filters';
import { Session } from '@/models/orm/Hierarchy';
import PlotlyGraph from '@/components/ui/charts/plotly/PlotlyGraph.vue';
import SessionChart from '@/modules/session/SessionChart.vue';
import FilterStores from '@/enums/StoreKeys';
// import ResultFilter from '@/filters/ResultFilter';
const companyLogo = process.env.VUE_APP_COMPANY_LOGO;

export default {
  name: 'PostGame',

  props: {
    session: {
      type: Session,
    },
    context: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return ['pitching', 'hitting'].includes(value);
      }
    },
    stats: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    PlotlyGraph,
    SessionChart,
  },

  metaInfo() {
    return {
      title: `Session ${this.session.id} Post Game ${this.$options.filters.capitalize(this.ctx)} Report`,
    };
  },

  data() {
    return {
      loading: false,
      hittingCharts: [
        { type: 'strike-zone', layout: { height: 400 } },
        { type: 'contact-report', layout: { height: 400 } },
        { type: 'spray-chart', layout: { height: 400 } },
        { type: 'batted-ball-quality', layout: { height: 400 } },
      ],
      pitchingCharts: [
        { type: 'release-point', layout: { height: 400 } },
        { type: 'release-extension', layout: { height: 400 } },
        { type: 'contact-report-pitching', layout: { height: 400 } },
        { type: 'spray-chart-pitching', layout: { height: 400 } },
      ],
      companyLogo,
      timeFormat: 'YYYY-MM-DD HH:mm',
    };
  },

  computed: {
    ctx() {
      switch (this.context) {
        case 'hitting':
          return 'batting';
        default:
          return this.context;
      }
    },

    provider() {
      const aggregator = new Calculators.RankingStatsAggregator();

      const filter = new Filters.RankingScopesFilter();

      return new Providers.PlayerLineDataProvider(aggregator, filter);
    },

    charts() {
      return this[`${this.context}Charts`];
    },

    playerIds() {
      return this[`${this.context}Ids`];
    },

    pitchingIds() {
      if (this.session) {
        const selectedPitchers = this.session.filters?.[FilterStores.PITCHING_POST_GAME]?.pitchers;
        if (selectedPitchers?.length) {
          return selectedPitchers;
        }

        return this.session.pitchers.map(tag => tag.id);
      }

      return [];
    },

    hittingIds() {
      if (this.session) {
        const selectedPitchers = this.session.filters?.[FilterStores.HITTING_POST_GAME]?.batters;
        if (selectedPitchers?.length) {
          return selectedPitchers;
        }

        return this.session.batters.map(tag => tag.id);

        // TODO: To use in case of using more filters
        // const resultsProcessor = new Entities.BaseballResultsProcessor();
        // const matchedResults = resultsProcessor.matchPitchesAndHits(this.session.resultsv1);

        // const {
        //   batters: batterIds,
        //   pitchers: pitcherIds,
        //   [ResultFilter.PitcherHandedness.key]: pitcherHandedness,
        //   [ResultFilter.PitchResult.key]: pitchResults,
        //   [ResultFilter.PitchType.key]: pitchTypes,
        //   [ResultFilter.ScoringOuts.key]: outs,
        //   [ResultFilter.ScoringBalls.key]: balls,
        //   [ResultFilter.ScoringRunnersOn.key]: runnersOn,
        // } = this.session.filters?.[FilterStores.HITTING_POST_GAME];

        // const chartFilters = genericChartFilters.buildFiltersForGenericChart({
        //   batterIds,
        //   pitcherHandedness,
        //   pitchResults,
        //   pitchTypes,
        //   pitcherIds,
        //   outs,
        //   balls,
        //   runnersOn,
        // }, true);

        // const resultPairsFiltered = resultsProcessor.filterData(matchedResults, chartFilters);

        // return uniq(resultPairsFiltered.reduce((acc, curr) => {
        //   if (curr?.pitch?.RelatedBatterTagID) {
        //     acc.push(curr.pitch.RelatedBatterTagID);
        //   }
        //   else if (curr?.hit?.TagID) {
        //     acc.push(curr.hit.TagID);
        //   }
        //   return acc;
        // }, []));
      }

      return [];
    },

    createDate() {
      return this.session.createDate;
    },

    modificationDate() {
      return this.session.modificationDate;
    },

    typeLabel() {
      return this.$vuetify.lang.t(`$vuetify.sessionTypes.${this.session.sessionType}`);
    },

    sessionSeason() {
      let home = this.session.homeLineup?.team?.season;
      let away = this.session.awayLineup?.team?.season;

      if (home) {
        if (away && home.id !== away.id) {
          this.$log.debug('Different seasons');
        }
        return home.displayName;
      }
      return null;
    },

    headers() {
      return {
        hitting: [
          // `Batting Line: ${final.Hits}/${final.NumberOfPitches}, ${final.TwoBaseHits} 2B, ${final.ThreeBaseHits} 3B, ${final.HomeRuns} HR, ${final.Walks} BB, ${final.Strikeouts} SO`;
          Enums.BattingRankingStats.Hits,
          Enums.BattingRankingStats.NumberOfPitches,
          Enums.BattingRankingStats.TwoBaseHits,
          Enums.BattingRankingStats.ThreeBaseHits,
          Enums.BattingRankingStats.HomeRuns,
          Enums.BattingRankingStats.Walks,
          Enums.BattingRankingStats.Strikeouts,
        ],
        pitching: [
          // Pitching Line: {InningsPitched} IP, {Strikeouts} SO, {WalksAllowed} BB, {HitsAllowed} H, {RunsAllowed} R
          Enums.PitchingRankingStats.InningsPitched,
          Enums.PitchingRankingStats.Strikeouts,
          Enums.PitchingRankingStats.WalksAllowed,
          Enums.PitchingRankingStats.HitsAllowed,
          Enums.PitchingRankingStats.RunsAllowed,
        ],
      };
    },
  },

  mounted() {
    this.toggleBodyClass('post-game-report', true);
  },

  destroyed() {
    this.toggleBodyClass('post-game-report');
  },

  methods: {
    print() {
      window.print();
    },

    lineFor(id) {
      return this.stats.length ? this[`${this.context}LineFor`](id) : '';
    },

    getStats(id) {

    },

    derivatives(stats) {
      const selector = new Selectors.DerivativeRankingStatCalculatorSelector();
      const derivativeProvider = new Providers.DerivativeRankingStatsProvider(selector);

      const contextHeaders = this.headers[this.context];

      const calculated = derivativeProvider.calculate(Object.values(stats), contextHeaders, this.ctx, true, false);

      const reduced = calculated.reduce((acc, curr, i) => {
        acc[curr.parameter] = curr.value;
        return acc;
      }, {});

      return reduced;
    },

    pitchingLineFor(id) {
      const stats = this.provider.getPitchingLine(this.stats, id);
      const final = this.derivatives(stats);
      if (this.session.hasExtendedTagging) {
        return `${final.Strikeouts} SO, ${final.WalksAllowed} BB, ${final.HitsAllowed} H`;
      }

      return `${final.InningsPitched} IP, ${final.Strikeouts} SO, ${final.WalksAllowed} BB, ${final.HitsAllowed} H, ${final.RunsAllowed} R`;
    },

    hittingLineFor(id) {
      const stats = this.provider.getBattingLine(this.stats, id);
      const final = this.derivatives(stats);

      return `${final.Hits}/${final.NumberOfPitches}, ${final.TwoBaseHits} 2B, ${final.ThreeBaseHits} 3B, ${final.HomeRuns} HR, ${final.Walks} BB, ${final.Strikeouts} SO`;
    },

    playerName(playerId) {
      const player = this.session.players.find(item => item.id === playerId);
      return player?.FormattedName || `Player #${playerId}`;
    },

    toggleBodyClass(className, addRemoveClass = false) {
      const el = document.body;

      if (addRemoveClass) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },
};
